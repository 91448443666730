import { QueryProducts } from "types";

export const getCategories = (products: QueryProducts[]): string[] => {
  return products.reduce((acc: string[], { node }) => {

    const findCategory = acc.find(item => item === node.category);

    if(!findCategory) {
      acc.push(node.category)
    }

    return acc;
  }, []);
}

export const replaceSpace =
  (str: string, value:string = '-') => str.toLowerCase().replace(/\s/g, value)

export default {
  getCategories,
  replaceSpace
}

