import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            user {
              email
            }
            title
            siteUrl
            description
            language
            themeColor
            author
            backgroundColor
            tags
          }
        }
      }
    `
  )
  return site.siteMetadata
}
