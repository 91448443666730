import React from "react";

import {
  Typography,
  Stack,
  Link,
  Button,
  SvgIcon,
  Container
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";

import { useSiteMetadata } from "common/hooks";

type LinkProps = {
  className?: string;
  children: React.ReactNode;
  to?: string;
  href?: string;
  ariaLabel?: string;
  target?: string;
  role?: string;
};

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link {...props} ref={ref} />
));


const renderLink = (
  url: string,
  label: string,
  Icon: typeof SvgIcon
): JSX.Element => (
  <Button
    component={AdapterLink}
    href={url}
    ariaLabel={label}
    color="inherit"
    role="link"
    target="_blank"
    style={{ textTransform: 'none', fontSize: '0.8rem' }}
  >
    <Icon fontSize="small" />&nbsp;{label}
  </Button>
);


type SiteConfig = {
    user:  {
      email: string
    },
}

const renderEmailLink = (config: Readonly<SiteConfig>): JSX.Element => {
  const url = `mailto:${config?.user?.email || ""}`;
  return renderLink(url, config?.user?.email, EmailIcon);
};

type StackProps = {
  component: string,
  spacing?: number,
}

const FooterStack = styled(Stack)<StackProps>(({theme}) => ({
  backgroundColor: theme.palette.mode === 'light' ?  theme.palette.grey[200] :  theme.palette.grey['900'],
  color: theme.palette.mode === 'light' ?  theme.palette.common.black :  theme.palette.common.white,
  padding: theme.spacing(1),
  width: '100%',
}));

const WrapContainer = styled(Container)`
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  color: ${props => props.theme.palette.grey[800]};
`

const Footer = (): JSX.Element => {
  const siteData = useSiteMetadata();
  console.log(siteData);
  return (
    <FooterStack component="footer">
      <WrapContainer>
        <Typography variant="caption" sx={{flex: 1}}>
          © Adventurer Sports SRL
        </Typography>
        <Typography variant="caption">
          {renderEmailLink(siteData)}
        </Typography>
      </WrapContainer>
    </FooterStack>
  );
};

export default Footer;
