import React from 'react';

import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalStorage } from "common/storage";
import { useSiteMetadata } from "common/hooks";

import { ThemeContext } from 'contexts/theme';

import CssReset from "./CssReset";

const getDesignTokens = (mode: PaletteMode, primary: string) => ({
    palette: {
      mode,
      primary: {
        main: primary,
      },
      success: {
        main: '#009900',
      },
      error: {
        main: '#ef2809',
      }
    }
});

const UIThemeProvider: React.FC = ({children}) => {
  const { themeColor } = useSiteMetadata()
  const [mode, setMode] = React.useState<PaletteMode>(LocalStorage.get('theme') || 'light');


  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode: PaletteMode) => {
            const newTheme = prevMode === 'light' ? 'dark' : 'light';
            LocalStorage.set('theme', newTheme)
            return newTheme;
          });

        },
      }),
      [],
  );

  const theme = React.useMemo(() =>
    createTheme(getDesignTokens(mode, themeColor)),
    [mode]);


  return (
      <ThemeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssReset />
            {children}
          </ThemeProvider>
      </ThemeContext.Provider>
  );
};

export default UIThemeProvider;
