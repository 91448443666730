
const localStorageKey = (key: string) => `Adventurer_Sports_App_${key}`;

const localStorageOperations = () => {
  return {
    get: (key: string) => {
      const isBrowser = typeof window !== "undefined"
      return isBrowser ? JSON.parse(<string>localStorage.getItem(localStorageKey(key))): ''
    },
    set: (key: string, value: string | object) => {
      const isBrowser = typeof window !== "undefined"
      return isBrowser && localStorage.setItem(localStorageKey(key), JSON.stringify(value))
    },
    remove: (key: string) => {
      const isBrowser = typeof window !== "undefined"
      isBrowser && localStorage.removeItem(localStorageKey(key))
    },
    clear: () => {
      const isBrowser = typeof window !== "undefined"
      isBrowser && localStorage.clear()
    },
  };
};



export const LocalStorage = localStorageOperations();
export const SessionStorage =  localStorageOperations();
