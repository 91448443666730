import React, { useState } from 'react';
import { Link } from "gatsby"
import { styled, useTheme } from '@mui/material/styles';

import {
  Container,
  Toolbar,
  AppBar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Drawer,
  CardMedia,
  Divider,
} from '@mui/material'
import { Menu, LightMode, DarkMode } from '@mui/icons-material';

import logo from 'assets/logo.png'

import { NavType } from 'types';

import { useThemeContext } from 'contexts/theme'

const NavCardMedia = styled(CardMedia)(() => ({
  width: 200,
  height: 42,
}));

type NavAppBarProps = {
  variant: string,
  elevation?: number
  position?: string
}

const NavAppBar = styled(AppBar)<NavAppBarProps>(({theme}) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.black :  theme.palette.grey['900'],
}));

const Navbar: React.FC<{ navList: NavType[]}> = ({children, navList}) => {
  const [isOpen, setDrawerOpen ] = useState(false);

  const theme = useTheme();
  const themeContext = useThemeContext();
  const toggleDrawer = () => {
    setDrawerOpen(!isOpen);
  }

  // @ts-ignore
  const handleDarkModeToggle = () => themeContext?.toggleColorMode();

  const renderThemeButton = (label?: string) => {
    const icon = theme.palette.mode === 'dark' ? (<LightMode color="inherit" />) : (<DarkMode color="inherit" />);

    return (
      <ListItemButton
        sx={{ my: 2, borderRadius: 8  }}
        onClick={handleDarkModeToggle}
      >
        {icon}
        {label &&  <ListItemText primary={label} sx={{marginLeft: 1}}/>}
        </ListItemButton>
    );
  }

  return (
      <Container sx={{ display: 'flex', alignItems: 'center', flexShrink: 0, minHeight: 80}}>
        <Drawer
          anchor="left"
          open={isOpen}
          variant="temporary"
          onClose={toggleDrawer}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <List>
              {navList.map((item: NavType) => (
                item.mobileComponent ||
                <ListItemButton
                  key={item.id}
                  to={item.to}
                  component={Link}
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              ))}
              <Divider variant="fullWidth"/>
              {renderThemeButton(`Temă ${theme.palette.mode !== 'dark' ?  'închisă': 'deschisă'}`)}
            </List>
          </Box>
        </Drawer>
        <NavAppBar variant="elevation" elevation={2} position="fixed">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer}
                  color="inherit"
                >
                  <Menu />
                </IconButton>
              </Box>
              <NavCardMedia image={logo}/>
              <Box sx={{ flexGrow: 1}} />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {navList.map( item => (
                  item.component ||
                  <ListItemButton
                    sx={{ my: 2, borderRadius: 8 }}
                    key={item.id}
                    to={item.to}
                    component={Link}
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ))}
                {renderThemeButton()}
              </Box>
              {children}
            </Toolbar>
          </Container>
        </NavAppBar>
      </Container>
  )
}

export default Navbar;
