import React from "react";

import { Container, Fade } from '@mui/material'
import {styled} from "@mui/material/styles";

import { navList } from 'layouts/config';

import Navbar from "components/Navbar";
import GlobalWrapper from "components/GlobalWrapper";


const WrapContainer = styled(Container)`
  padding: ${props => `0 ${props.theme.spacing(3)}`};
  margin-bottom: ${props => props.theme.spacing(6)};
  ${props => props.theme.breakpoints.up("sm")} {
    margin-top:  ${props => props.theme.spacing(3)};
  }
`


const MainLayout: React.FC<{title:string}> = ({children}) => {
  return (
    <GlobalWrapper>
      <Navbar navList={navList}/>
      <Fade in mountOnEnter unmountOnExit>
        <WrapContainer maxWidth="lg">
          {children}
        </WrapContainer>
      </Fade>
    </GlobalWrapper>
  );
}

export default MainLayout;
