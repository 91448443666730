import React from 'react'
import {graphql, Link, StaticQuery} from "gatsby";

import {
  Menu,
  Fade,
  ListItemButton,
  ListItemText
} from '@mui/material'
import { replaceSpace } from "utilities";

type ProductQueryProps = {
  distinct: string[],
}

/**
 * Categories
 */
const Categories: React.FC<{ allProduct: ProductQueryProps}> = ( { allProduct}) => {
  const categories  = allProduct?.distinct;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItemButton
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ my: 2, borderRadius: 8  }}
      >
        <ListItemText primary="Produse" />
      </ListItemButton>
      <Menu
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <ListItemButton
          key='all-products'
          to='/products'
          component={Link}
          onClick={handleClose}
        >
          <ListItemText primary="Toate produsele" />
        </ListItemButton>
        {
          categories.map(item => {
            const toString = '/products/'+ replaceSpace(item)
            return (
              <ListItemButton
                key={toString}
                to={toString}
                onClick={handleClose}
                component={Link}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            )
          })
        }
      </Menu>
    </>
  )
}
export const query = graphql`
query {
  allProduct {
    distinct(field: category)
  }
}
`
const CategoriesQuery: React.FC = () => {
  return <StaticQuery query={query} render={Categories}/>
}


export default CategoriesQuery;
