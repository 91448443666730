import React from 'react'

import { Box } from '@mui/material';

import Footer from "components/Footer";

import UIThemeProvider from './UIThemeProvider'

/**
 * GlobalWrapper
 */
const GlobalWrapper: React.FC = ({children}) => {
  return (
    <UIThemeProvider>
      <Box
        sx={{
          minHeight: "calc(100vh - 56px)",
          minWidth: "100%",
        }}
      >
      {children}
      </Box>
      <Footer />
    </UIThemeProvider>
  )

}

export default GlobalWrapper;
