import React from 'react'
import { graphql, Link, StaticQuery } from "gatsby";

import {
  ListItemButton,
  ListItemText,
  Collapse,
  List,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { replaceSpace } from "utilities";


type ProductQueryProps = {
  distinct: string[],
}

/**
 * Categories
 */
const CategoriesMobile: React.FC<{ allProduct: ProductQueryProps}> = ( { allProduct}) => {
  const categories  = allProduct?.distinct;
  const [open, setOpen] = React.useState(true);

  const handleClick = (event: any) => {
    setOpen(!open);
    event.stopPropagation();
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Produse" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            key='all-products'
            to='/products'
            component={Link}
          >
            <ListItemText primary="Toate produsele" />
          </ListItemButton>
          {
            categories.map(item => {
              const toString = `/products/${replaceSpace(item)}`;
              return (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={toString}
                  to={toString}
                  component={Link}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              )
            })
          }

        </List>
      </Collapse>
    </>
  )
}
export const query = graphql`
query {
  allProduct {
    distinct(field: category)
  }
}
`
const CategoriesMobileQuery: React.FC = () => {
  return <StaticQuery query={query} render={CategoriesMobile}/>
}


export default CategoriesMobileQuery;
