import React from "react";

import Categories from "components/Navbar/Categories";
import CategoriesMobile from "components/Navbar/CategoriesMobile";

export const navList = [{
    id: 'home',
    label: 'Acasă',
    to: '/',
}, {
    id: 'products',
    label: 'Produse',
    to: '/products',
    component: <Categories />,
    mobileComponent: <CategoriesMobile />
}, {
    id: 'about',
    label: 'Despre noi',
    to: '/about',
}]
